import { Button, ButtonProps } from "@material-ui/core";
import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import React, { FC } from "react";
import styles from "./PersonalizeCta.module.css";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

// This is a button to open comms variables picker on it
// ref: src/ui/pages/EmailDrip/components/EmailContentModal/EmailContentModal.jsx
const PersonalizeCta: FC<ButtonProps & { active?: boolean }> = (props) => {
  const { active, children = "Personalize", className, ...buttonProps } = props;

  return (
    <Button
      className={classnames(
        styles.personalize,
        active && styles.active,
        className
      )}
      startIcon={
        <ExlyImage
          alt="personalize"
          src={getS3AssetCDNUrl(
            "assets/vectors/sales_and_marketing/broadcast/electric_bolt.svg"
          )}
          className={undefined}
          height={undefined}
          width={undefined}
          loading={undefined}
          id={undefined}
        />
      }
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default PersonalizeCta;
