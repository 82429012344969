import { exly_env_name } from "features/Common/modules/Environment/Environment.constants";
import {
  HTML_MIME_TYPE,
  JSON_MIME_TYPE,
  file_format_keys,
} from "features/Common/modules/File/File.constants";
import { uploadToS3 } from "features/Common/modules/S3/utils/S3.utils";
import { logError } from "utils/error";
import {
  max_file_size_in_kb,
  max_html_file_size,
} from "../constants/EmailBodyEditor.constants";

export async function fetchHTMLString(htmlURL) {
  if (!htmlURL) return;
  try {
    // check whether url is valid or not... will go to catch block if its not a valid url
    const modifiedURL = new URL(htmlURL);

    // add a dummy timestamp to avoid serving cached value
    modifiedURL.searchParams.set("ts", Date.now());

    const response = await fetch(modifiedURL.href);
    if (response.ok) return response.text();

    // else: file is not accessible
    throw new Error("html file is not accessible");
  } catch (e) {
    logError({
      error: e,
      occuredAt:
        "src/features/EmailBuilder/modules/EmailBodyEditor/utils/projectData&HTML.js",
      when: "calling fetchHTMLString",
      extraErrorData: { htmlURL },
    });
  }
}

const getHTMLFileUploadKey = ({ file }) =>
  `${exly_env_name}/email-builder/${file.name}`;

const uploadHTMLString = async ({ html, id, featurePrefix }) => {
  try {
    // Step: create HTML file
    const file = new File(
      [html],
      featurePrefix + "-" + id + "." + file_format_keys.html,
      { type: HTML_MIME_TYPE }
    );

    if (file.size > max_html_file_size) {
      throw new Error(
        `File size cannot be greater than ${max_file_size_in_kb}MB`
      );
    }

    // Step: upload to CDN
    const uploadedFile = await uploadToS3({
      file,
      key: getHTMLFileUploadKey({ file }),
      bucket: process.env.REACT_APP_EXLY_S3_BUCKET_HTML_FILES,
      contentType: HTML_MIME_TYPE,
    });

    return uploadedFile;
  } catch (error) {
    logError({
      error,
      occuredAt:
        "src/features/EmailBuilder/modules/EmailBodyEditor/utils/projectData&HTML.js",
      when: "uploadHTMLString",
    });
    throw error;
  }
};

const fetchProjectData = async (projectDataFileUrl) => {
  if (!projectDataFileUrl) return;

  try {
    // check whether url is valid or not... will go to catch block if its not a valid url
    const modifiedURL = new URL(projectDataFileUrl);

    // add a dummy timestamp to avoid serving cached value
    modifiedURL.searchParams.set("ts", Date.now());

    const response = await fetch(modifiedURL.href);
    if (response.ok) return response.json();

    // else: file is not accessible
    throw new Error("projectData file is not accessible");
  } catch (e) {
    logError({
      error: e,
      occuredAt:
        "src/features/EmailBuilder/modules/EmailBodyEditor/utils/projectData&HTML.js",
      when: "calling fetchProjectData",
      extraErrorData: { projectDataFileUrl },
    });
  }
};

const getProjectDataFileUploadKey = ({ file }) =>
  `${exly_env_name}/email-builder-project-data/${file.name}`;

const uploadProjectData = async ({ projectData, id, featurePrefix }) => {
  try {
    if (!projectData) return;

    const file = new File(
      [JSON.stringify(projectData)],
      featurePrefix + "-" + id + "." + file_format_keys.json,
      { type: JSON_MIME_TYPE }
    );

    if (file.size > max_html_file_size) {
      throw new Error(
        `Project Data File size cannot be greater than ${max_file_size_in_kb}MB`
      );
    }

    const uploadedFile = await uploadToS3({
      file,
      key: getProjectDataFileUploadKey({ file }),
      bucket: process.env.REACT_APP_EXLY_S3_BUCKET_HTML_FILES,
      contentType: JSON_MIME_TYPE,
    });

    return uploadedFile;
  } catch (error) {
    logError({
      error,
      occuredAt:
        "src/features/EmailBuilder/modules/EmailBodyEditor/utils/projectData&HTML.js",
      when: "uploadProjectData",
    });
    throw error;
  }
};

export const fetchProjectDataNHtmlString = async ({
  htmlFileLink,
  projectDataFileLink,
}) => {
  const fetchPromises = [
    fetchProjectData(projectDataFileLink),
    fetchHTMLString(htmlFileLink),
  ];
  const responses = await Promise.allSettled(fetchPromises);
  const values = responses.map((item) =>
    item.status === "fulfilled" ? item.value : null
  );
  const [projectData, html] = values;
  return { projectData, html };
};

export const uploadProjectDataNHTMLString = async ({
  html,
  projectData,
  id,
  featurePrefix,
}) => {
  const uploadPromises = [];
  uploadPromises.push(uploadHTMLString({ html, id, featurePrefix }));
  uploadPromises.push(uploadProjectData({ projectData, id, featurePrefix }));
  const uploadResponses = await Promise.allSettled(uploadPromises);

  const uploadFailed = uploadResponses.some(
    (item) => item.status === "rejected"
  );

  const uploadedFileLocations = uploadResponses.map((item) =>
    item.status === "fulfilled" ? item?.value?.Location : null
  );
  const [htmlFileLocation, projectDataFileLocation] = uploadedFileLocations;

  return { uploadFailed, htmlFileLocation, projectDataFileLocation };
};
