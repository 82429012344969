export const EMAIL_BROADCAST_CUSTOM_ROUTE_KEYS = {
  EMAIL_BROADCAST: "EMAIL_BROADCAST",
};

export const EMAIL_BROADCAST_ROUTE_IDS = {
  [EMAIL_BROADCAST_CUSTOM_ROUTE_KEYS.EMAIL_BROADCAST]:
    "/notifications/email/list",
};

export const EMAIL_BROADCAST_MAIN_PATH =
  EMAIL_BROADCAST_ROUTE_IDS[EMAIL_BROADCAST_CUSTOM_ROUTE_KEYS.EMAIL_BROADCAST];
