import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

// editor types start
export const EMAIL_BUILDER_TYPE_BASIC = 1;
export const EMAIL_BUILDER_TYPE_ADVANCE = 2;
// editor types end

// editor stages start
export const EMAIL_BUILDER_STAGE_EDITOR_TYPE_SELECTOR = "editor-type-selector"; // initial stage, when the editor is being visited for the first time.
export const EMAIL_BUILDER_STAGE_PREVIEW = "preview"; // initial stage, when component is opened with a saved email is being opened.
export const EMAIL_BUILDER_STAGE_BASIC_EDITOR = "basic-editor"; // when basic editor is being opened from EMAIL_BUILDER_STAGE_EDITOR_TYPE_SELECTOR stage or EMAIL_BUILDER_STAGE_PREVIEW stage
export const EMAIL_BUILDER_STAGE_ADVANCE_EDITOR = "advance-editor"; // when advance editor is being opened from EMAIL_BUILDER_STAGE_EDITOR_TYPE_SELECTOR stage or EMAIL_BUILDER_STAGE_PREVIEW stage
// editor stages end

// assets start
export const EMAIL_BUILDER_ASSET_SRC_SELECT_TOOL_OPTION_BASIC =
  getS3AssetCDNUrl("assets/vectors/email_builder/select-tool-option-basic.svg");
export const EMAIL_BUILDER_ASSET_SRC_SELECT_TOOL_OPTION_ADVANCE =
  getS3AssetCDNUrl(
    "assets/vectors/email_builder/select-tool-option-advance.svg"
  );
// assets end

export const max_file_size_in_kb = 300;
export const max_html_file_size = max_file_size_in_kb * 1024;
